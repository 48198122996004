<template>
    <v-card class="desktop_card">
        <v-card-title>
            <v-row no-gutters>
                <v-col class="text-left" cols="7">
                    <label class="--v-primary-base text-left text-capitalize">{{ $t(`label.bankDetail`) }}</label>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.mdAndDown" class="text-center" cols="5">
                    <!-- <v-btn v-if="$vuetify.breakpoint.mdAndDown" :ripple="false" outlined rounded class="text-uppercase font-weight-bold text-center navigator_active--text" @click="openNewBankAccountDialog">{{ $t(`button.add`) }}
                    </v-btn> -->
                    <v-btn class="text-capitalize px-10" color="primary" depressed @click="openNewBankAccountDialog">{{$t(`button.add`)}}</v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card v-if="!bankAccountDialogShow && $vuetify.breakpoint.lgAndUp " height="80%" class="profile-detail-card" elevation="0">
            <v-row no-gutters align="end" justify="start" class="px-8 pa-4">
                <v-col cols="3">
                    <label class="field-name">{{ $t(`field.bank`) }}</label>
                </v-col>
                <v-col cols="4">
                    <label class="field-name">{{ $t(`field.bankHolderName`) }}</label>
                </v-col>
                <v-col cols="3">
                    <label class="field-name">{{ $t(`field.depositingBankAccountNumber`) }}</label>
                </v-col>
                <v-col class="text-right" cols="2">
                    <!-- <v-btn :ripple="false" outlined rounded class="text-uppercase font-weight-bold text-center navigator_active--text" @click="openNewBankAccountDialog">{{ $t(`button.add`) }}
                    </v-btn> -->
                    <v-btn class="text-capitalize px-10" color="primary" depressed @click="openNewBankAccountDialog">{{$t(`button.add`)}}</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters class="px-8">
                <v-col cols=12>
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row no-gutters align="end" class="px-8 pt-4" v-for="(bankAccount, index) in memberBankAccounts" :key="bankAccount.code">
                <v-col cols="3">
                    <label>{{ bankAccount.bankName }}</label>
                </v-col>
                <v-col cols="4">
                    <label>{{ bankAccount.accountName }}</label>
                </v-col>
                <v-col cols="3">
                    <label>{{ bankAccount.accountNumber }}</label>
                </v-col>
                <v-col cols=12 class="pt-4">
                    <v-divider v-if="index !== memberBankAccounts.length - 1"></v-divider>
                </v-col>
            </v-row>
            <v-row no-gutters align="end" class="px-8 pt-4" v-if="memberBankAccounts.length == 0">
                <v-col cols=12 class="pt-4 text-center">
                    <label class="field-name">{{ $t(`label.noBankAccount`)}}</label>
                </v-col>
            </v-row>
        </v-card>
        <v-card v-else-if="!bankAccountDialogShow && $vuetify.breakpoint.mdAndDown " height="80%" class="profile-detail-card" elevation="0">
            <v-row no-gutters align="center" justify="start" class="px-8 pa-4">
                <v-col cols=12 v-for="bankAccount in memberBankAccounts" :key="bankAccount.code">
                <v-row no-gutters class="py-1">
                <v-col cols="6">
                    <label class="field-name">{{ $t(`field.bank`) }}</label>
                </v-col>
                <v-col cols="6">
                    <label>{{ bankAccount.bankName }}</label>
                </v-col>
                </v-row>
                <v-row no-gutters class="py-1">
                <v-col cols="6">
                    <label class="field-name">{{ $t(`field.bankHolderName`) }}</label>
                </v-col>
                <v-col cols="6">
                    <label>{{ bankAccount.accountName }}</label>
                </v-col>
                </v-row>
                <v-row no-gutters class="py-1">
                <v-col cols="6">
                    <label class="field-name">{{ $t(`field.depositingBankAccountNumber`) }}</label>
                </v-col>
                <v-col cols="6">
                    <label>{{ bankAccount.accountNumber }}</label>
                </v-col>
                </v-row>
                <v-row no-gutters class="py-3">
                <v-col cols="12">
                <v-divider class=""></v-divider>
                </v-col>
                </v-row>
                </v-col>
            </v-row>
        </v-card>
        <v-card v-if="bankAccountDialogShow" height="80%" class="profile-detail-card" elevation="0">
            <v-form ref="bankAccountForm">
                <v-row no-gutters align="end" class="px-8 pt-4">
                    <v-col lg=4 md=5>
                        <app-form-field :class="'mt-2'" v-model.trim="selectedBankCodeValue" :enabledLabel=true :items="banks" :label="$t(`field.bank`)" :placeholder="$t(`fieldHint.fillUpHere`)" type="select" :rules="this.selectedBankCodeValue == 0 ? [] : validator.dropDownListRules()"
                            :displayText="this.getBankDisplayText"></app-form-field>
                    </v-col>
                </v-row>
                <v-row no-gutters align="end" class="px-8">
                    <v-col lg=4 md=5>
                        <app-form-field v-if="this.selectedBankCodeValue == '0'" v-model.trim="otherBankName" :enabledLabel=true :label="$t(`field.bankName`)" :placeholder="$t(`fieldHint.fillUpHere`)" :rules="validator.requiredRules(1, 50)"></app-form-field>
                    </v-col>
                </v-row>
                <v-row no-gutters align="end" class="px-8">
                    <v-col lg=4 md=5>
                        <app-form-field :readonly="memberInfo.name != null && memberInfo.name != ''" v-model.trim="bankAccountObj.memberBankAccountName" :enabledLabel=true :label="$t(`field.accountName`)" :placeholder="$t(`fieldHint.fillUpHere`)" :rules="validator.requiredRules(1, 50)"></app-form-field>
                    </v-col>
                </v-row>
                <v-row no-gutters align="end" class="px-8">
                    <v-col lg=4 md=5>
                        <app-form-field v-model.trim="bankAccountObj.memberBankAccountNumber" :enabledLabel=true :label="$t(`field.accountNumber`)" :placeholder="$t(`fieldHint.fillUpHere`)" :rules="validator.bankAccountNumberRules(1, 20)"></app-form-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="px-8">
                  <v-col lg=4 md=5 :align="end">
                    <app-button :class="$vuetify.breakpoint.mdAndDown ? 'bank-detail-mobile dialog-button' : 'bank-detail dialog-button'" :action="this.addBankAccount" :title="$t(`button.submit`)"></app-button>
                    <app-button :class="$vuetify.breakpoint.mdAndDown ? 'bank-detail-mobile dialog-button-cancel' : 'bank-detail dialog-button-cancel'" :action="this.closeBankAccountDialog" :title="$t(`button.cancel`)"></app-button>
                  </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-card>
</template>

<script>
    import AppAccount from '@/components/bank/account.vue'
    import {
        SESSION
    } from '@/constants/constants'
    import {
        uiHelper,
        locale,
        errorCodeHelper,
        formValidator
    } from '@/util'
    import stingFormat from 'string-format'
    import { MEMBER_DETAIL } from '@/store/member.module'
    import {
        PAYMENT_DELETE_MEMBER_BANK_ACCOUNT,
        PAYMENT_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE,
        PAYMENT_UPDATE_MEMBER_BANK_ACCOUNT,
        PAYMENT_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE,
        PAYMENT_NEW_MEMBER_BANK_ACCOUNT,
        PAYMENT_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE
    } from '@/store/payment.module'
    import {
        ROUTE_NAME
    } from "@/constants/route.constants";
    export default {
        name: 'bankAccount',
        metaInfo: {
            title: `${locale.getMessage('meta.bankInfo_title')}`,
            titleTemplate: `%s`,
            meta: [{
                charset: 'utf-8'
            }, {
                name: 'description',
                content: `${locale.getMessage('meta.bankInfo_description')}`
            }, {
                name: 'keyword',
                content: `${locale.getMessage('meta.bankInfo_keyword')}`
            }]
        },
        components: {
            AppAccount
        },
        data: () => ({
            noAccount: true,
            isUpdateBank: true,
            bankAccountDialogShow: false,
            playerBankAccountName: '',
            transactionObj: {
                /* MEMBER INFO */
                memberBankAccountId: '',
                memberBankAccountName: '',
                memberBankAccountNumber: '',
                memberBankName: '',
            },
            bankAccountObj: {
                memberBankAccountId: '',
                memberBankAccountName: '',
                memberBankAccountNumber: '',
                memberBankName: '',
                memberBankProvince: '',
                memberBankBranch: '',
                memberBankCity: '',
                saveNewBank: false,
                memberCode: localStorage.getItem(SESSION.USERNAME),
                isUpdateBank: false
            },
            personalInfo: {},
            validator: formValidator,
            stingFormat: stingFormat,
            selectedBankCodeValue: '',
            otherBankName: ''
        }),
        computed: {
            memberInfo() {
              return this.$store.state.member.info
            },
            banks() {
                return this.$store.state.payment.banks
            },
            memberBankAccounts() {
                return this.$store.state.payment.memberBankAccounts.filter(x => x.id != '0')
            },
            deleteMemberBankAccountResponseComplete() {
                return this.$store.state.payment.deleteMemberBankAccountResponse.complete
            },
            updateMemberBankAccountResponseComplete() {
                return this.$store.state.payment.updateMemberBankAccountResponse.complete
            },
            newMemberBankAccountResponseComplete() {
                return this.$store.state.payment.newMemberBankAccountResponse.complete
            }
        },
        watch: {
            memberBankAccounts() {
                //this.preSelectMemberBankAccount()
            },
            deleteMemberBankAccountResponseComplete() {
                let response = this.$store.state.payment.deleteMemberBankAccountResponse
                if (response.complete) {
                    this.deleteMemberBankAccountResponseCompleteDialog(response)
                    this.$parent.getMemberBankAccount()
                    this.$store.dispatch(`${PAYMENT_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE}`)
                }
            },
            updateMemberBankAccountResponseComplete() {
                let response = this.$store.state.payment.updateMemberBankAccountResponse
                if (response.complete) {
                    this.updateMemberBankAccountResponseCompleteDialog(response)
                    this.$parent.getMemberBankAccount()
                    this.$store.dispatch(`${PAYMENT_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE}`)
                }
            },
            newMemberBankAccountResponseComplete() {
                let response = this.$store.state.payment.newMemberBankAccountResponse
                if (response.complete) {
                    this.newMemberBankAccountResponseCompleteDialog(response)
                    this.$parent.getMemberBankAccount()
                    this.$store.dispatch(`${PAYMENT_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE}`)
                    if (response.success) {
                        // this.$router.push({
                        //     name: ROUTE_NAME.BANK_ACCOUNT,
                        // })
                        window.location.reload();
                    }
                }
            }
        },
        created() {
            this.$parent.getBank()
            this.$parent.getMemberBankAccount()
            this.checkMemberDetail()
        },
        methods: {
            checkMemberDetail() {
              this.$store.dispatch(MEMBER_DETAIL)
            },
            preSelectMemberBankAccount() {
                if (this.memberBankAccounts.length > 0) {
                    let memberBankAccounts = this.memberBankAccounts.find(x => x.id != '0')
                    if (memberBankAccounts != null && memberBankAccounts != undefined) {
                        this.transactionObj.memberBankAccountId = memberBankAccounts.id
                        this.transactionObj.memberBankName = memberBankAccounts.bankName
                        this.transactionObj.memberBankAccountNumber = memberBankAccounts.accountNumber
                        this.transactionObj.memberBankAccountName = memberBankAccounts.accountName
                        this.noAccount = false
                    } else {
                        this.noAccount = true
                    }
                } else {
                    this.noAccount = true
                }
            },
            confirmationDeleteMemberBankAccount(data) {
                let dialog = this.$parent.$parent.initializePageDialogProperty()
                dialog.title = locale.getMessage(`message.deleteBankAccount`)
                dialog.message.push(locale.getMessage(`message.confirmDeleteBankAccount`))
                dialog.message.push(locale.getMessage('field.bankName') + ' : ' + data.bankName)
                dialog.message.push(locale.getMessage('field.accountName') + ' : ' + data.accountName)
                dialog.message.push(locale.getMessage('field.accountNumber') + ' : ' + data.accountNumber)
                dialog.button.push({
                    title: locale.getMessage(`label.confirm`),
                    action: () => this.deleteMemberBankAccount(data)
                })
                dialog.button.push({
                    title: locale.getMessage(`label.cancel`),
                    action: this.$parent.$parent.closePageDialog
                })
                this.$parent.$parent.openPageDialog(dialog)
            },
            deleteMemberBankAccount(data) {
                this.$parent.$parent.closePageDialog
                let obj = {
                    memberBankAccountId: data.id
                }
                this.$store.dispatch(`${PAYMENT_DELETE_MEMBER_BANK_ACCOUNT}`, {
                    obj
                })
            },
            deleteMemberBankAccountResponseCompleteDialog(response) {
                let dialog = this.$parent.$parent.initializePageDialogProperty()
                dialog.title = locale.getMessage(`label.${response.action}`)
                if (response.code != 0 && !response.success) {
                    dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
                    dialog.button.push({
                        title: locale.getMessage(`label.close`),
                        action: this.$parent.$parent.closePageDialog
                    })
                } else {
                    dialog.message.push(locale.getMessage(`message.memberBankAccountDeleted`))
                    dialog.button.push({
                        title: locale.getMessage(`label.ok`),
                        action: this.$parent.$parent.closePageDialog
                    })
                }
                this.$parent.$parent.openPageDialog(dialog)
            },
            updateBankAccountData(obj) {
                this.$store.dispatch(`${PAYMENT_UPDATE_MEMBER_BANK_ACCOUNT}`, {
                    obj
                })
            },
            updateMemberBankAccountResponseCompleteDialog(response) {
                let dialog = this.$parent.$parent.initializePageDialogProperty()
                dialog.title = locale.getMessage(`label.${response.action}`)
                if (response.code != 0 && !response.success) {
                    dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
                    dialog.button.push({
                        title: locale.getMessage(`label.close`),
                        action: this.$parent.$parent.closePageDialog
                    })
                } else {
                    this.closeBankAccountDialog()
                    dialog.message.push(locale.getMessage(`message.memberBankAccountUpdated`))
                    dialog.button.push({
                        title: locale.getMessage(`label.ok`),
                        action: this.$parent.$parent.closePageDialog
                    })
                }
                this.$parent.$parent.openPageDialog(dialog)
            },
            newBankAccountData(bankAccountObj) {
                this.$store.dispatch(`${PAYMENT_NEW_MEMBER_BANK_ACCOUNT}`, {
                    bankAccountObj
                })
            },
            newMemberBankAccountResponseCompleteDialog(response) {
                if (response.code != 0 && !response.success) {
                    this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
                } else {
                    this.$parent.openAlert(true, locale.getMessage(`message.memberBankAccountCreated`))
                }
            },
            submitBankAccountData(data) {
                if (data.isUpdateBank) {
                    this.updateBankAccountData(data)
                } else {
                    this.newBankAccountData(data)
                }
            },
            openEditBankAccountDialog(data) {
                this.bankAccountObj = {
                    memberBankAccountId: data.id,
                    memberBankAccountName: data.accountName,
                    memberBankAccountNumber: data.accountNumber,
                    memberBankName: data.bankName,
                    memberBankProvince: data.bankProvince,
                    memberBankBranch: data.bankBranch,
                    memberBankCity: data.bankCity,
                    saveNewBank: true,
                    memberCode: localStorage.getItem(SESSION.USERNAME),
                    isUpdateBank: true
                }
                this.bankAccountDialogShow = true
            },
            openNewBankAccountDialog() {
                this.bankAccountObj = null
                this.bankAccountObj = {
                    memberBankAccountName: this.memberInfo.name,
                    memberBankAccountId: this.transactionObj.memberBankAccountId,
                    memberCode: localStorage.getItem(SESSION.USERNAME)
                }
                this.bankAccountDialogShow = true
            },
            closeBankAccountDialog() {
                this.bankAccountDialogShow = false
            },
            addBankAccount() {
                if (this.$refs.bankAccountForm.validate()) {
                    if (this.selectedBankCodeValue != 0 && this.selectedBankCodeValue != '0') {
                        this.bankAccountObj.memberBankAccountId = this.bankAccountObj.memberBankAccountId != '' ? this.bankAccountObj.memberBankAccountId : '0'
                        this.bankAccountObj.memberBankName = this.banks.find(x => x.value == this.selectedBankCodeValue).name
                        this.bankAccountObj.bankCode = this.selectedBankCodeValue
                    } else {
                        //this.bankAccountObj.memberBankAccountId = this.selectedBankCodeValue.toString()
                        this.bankAccountObj.memberBankName = this.otherBankName
                    }
                    /* Force Save Bank if Compenent Open as Edit Bank */
                    if (this.isUpdateBank) this.bankAccountObj.saveNewBank = true
                    /* Update Value on Deposit or Withdrawal */
                    this.submitBankAccountData(this.bankAccountObj)
                }
            },
            getBankDisplayText(data) {
                if (data.value == 0) {
                    return locale.getMessage(`bankName.${data.displayText}`)
                } else {
                    return data.displayText
                }
            }
        }
    }
</script>

<style lang="scss">
    .bank-account-card-container {
        max-height: 550px;
        overflow-y: auto;
    }
    .card_container {
        margin-top: 70px;
        border-radius: 20px !important;
        &.nonBanking {
            margin-top: 0;
        }
    }
    .bank-detail.dialog-button {
      margin-left: 10px;
      color: whitesmoke;
    }
    .bank-detail.dialog-button-cancel {
      margin-left: 10px;
      color: whitesmoke;
      background-color: var(--v-secondary-base) !important;
    }

    .bank-detail-mobile.dialog-button {
      margin-bottom: 10px;
      color: whitesmoke;
    }
    .bank-detail-mobile.dialog-button-cancel {
      margin-bottom: 10px;
      color: whitesmoke;
      background-color: var(--v-secondary-base) !important;
    }
    @media (max-width: 599px) {
        .card_container {
            margin-top: 20px;
            border-radius: 20px !important;
        }
      .bank-detail-mobile.dialog-button-cancel {
        width: 100%;
      }
    }
</style>
